import { CSSProperties } from "react";

interface MatchaIconProps {
    className?: string;
    style?: CSSProperties;
}

export const MatchaIcon: React.FC<MatchaIconProps> = ({ className = "", style }) => (
    <div className={`matcha-icon ${className}`} style={style}>
        <svg width="96" height="96" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.7918 2.6688C17.7482 2.756 17.6437 2.79325 17.5547 2.75328C16.2312 2.15103 14.6226 1.94755 13.5653 1.94755C9.76389 1.94755 5.96159 3.80154 5.96159 6.84913C5.96159 8.48329 7.18694 9.86584 8.97818 9.86584C10.9574 9.86584 12.4344 8.42062 13.628 7.10075C14.6962 5.90715 15.9533 4.58728 17.7436 4.58728C19.692 4.58728 21.1054 6.0325 21.1054 7.98096C21.1054 11.0603 17.3976 14.5167 12.2763 14.5167C7.15514 14.5167 3.44822 11.7825 3.44822 7.98096C3.44822 4.43013 7.65836 1.22539 12.2773 1.22539C14.6344 1.22539 16.4093 1.71955 17.7155 2.42808C17.8018 2.47532 17.8354 2.5816 17.7918 2.66971V2.6688ZM12.2773 0C5.96159 0 0.808594 5.1532 0.808594 11.4691C0.808594 17.785 5.96159 23.0009 12.2773 23.0009C18.5929 23.0009 23.8086 17.8477 23.8086 11.4691C23.8086 5.09052 18.6547 0 12.2773 0Z" fill="currentColor">
            </path>
        </svg>
    </div>
);

interface UniswapIconProps {
    className?: string;
    style?: CSSProperties;
}

export const UniswapIcon: React.FC<UniswapIconProps> = ({ className = "", style }) => (
    <div className={`uniswap-icon ${className}`} style={style}>
        <svg width="96" height="96" viewBox="0 0 400 434" xmlns="http://www.w3.org/2000/svg">
            <path d="M325.144 65.7698C325.727 55.5419 327.119 48.7957 329.918 42.6348C331.025 40.1962 332.063 38.2003 332.223 38.2003C332.383 38.2003 331.902 40.0003 331.153 42.1999C329.118 48.1791 328.784 56.3572 330.186 65.872C331.965 77.9447 332.976 79.6866 345.782 92.7278C351.788 98.8446 358.775 106.559 361.307 109.871L365.912 115.894L361.307 111.59C355.676 106.327 342.724 96.0618 339.863 94.5945C337.945 93.6104 337.66 93.6274 336.477 94.801C335.387 95.8823 335.157 97.5072 335.005 105.189C334.77 117.16 333.132 124.845 329.18 132.528C327.042 136.684 326.705 135.797 328.64 131.106C330.084 127.604 330.231 126.064 330.22 114.475C330.198 91.1888 327.424 85.5906 311.153 76.0005C307.032 73.5711 300.24 70.0674 296.062 68.2141C291.883 66.3608 288.564 64.7467 288.685 64.6261C289.146 64.1691 305.013 68.7839 311.399 71.2318C320.899 74.8731 322.467 75.3449 323.621 74.9057C324.394 74.6113 324.768 72.367 325.144 65.7698Z" fill="currentColor" />
            <path d="M124.431 31.8931C118.795 31.0236 118.557 30.9216 121.209 30.5162C126.292 29.7389 138.292 30.7983 146.562 32.754C165.867 37.3182 183.434 49.0103 202.186 69.7754L207.168 75.2919L214.295 74.1524C244.318 69.3537 274.861 73.1675 300.407 84.9049C307.435 88.134 318.516 94.5618 319.9 96.2136C320.341 96.7401 321.151 100.129 321.699 103.745C323.597 116.255 322.647 125.843 318.8 133.005C316.707 136.903 316.59 138.138 317.997 141.473C319.121 144.135 322.253 146.105 325.355 146.1C331.703 146.092 338.536 135.885 341.702 121.683L342.96 116.042L345.452 118.849C359.117 134.248 369.85 155.248 371.694 170.196L372.174 174.093L369.877 170.55C365.924 164.454 361.952 160.303 356.866 156.956C347.697 150.922 338.002 148.868 312.327 147.523C289.139 146.307 276.015 144.337 263.002 140.117C240.861 132.937 229.699 123.375 203.399 89.0559C191.717 73.8123 184.497 65.3784 177.314 58.5864C160.994 43.1536 144.958 35.0599 124.431 31.8931Z" fill="currentColor" />
            <path d="M135.377 105.713C123.937 90.0473 116.858 66.0289 118.391 48.0742L118.864 42.518L121.469 42.9906C126.359 43.8778 134.791 46.9992 138.739 49.3844C149.574 55.9291 154.265 64.5458 159.037 86.6722C160.435 93.1531 162.269 100.487 163.113 102.97C164.472 106.967 169.605 116.302 173.779 122.365C176.785 126.731 174.788 128.8 168.143 128.204C157.994 127.293 144.246 117.856 135.377 105.713Z" fill="currentColor" />
            <path d="M311.254 222.301C257.789 200.891 238.958 182.307 238.958 150.95C238.958 146.336 239.118 142.56 239.313 142.56C239.507 142.56 241.576 144.083 243.909 145.945C254.751 154.593 266.891 158.287 300.501 163.164C320.279 166.034 331.408 168.352 341.675 171.738C374.308 182.502 394.497 204.345 399.312 234.098C400.711 242.743 399.891 258.955 397.623 267.5C395.832 274.248 390.367 286.413 388.918 286.879C388.516 287.009 388.122 285.477 388.019 283.393C387.469 272.221 381.793 261.345 372.26 253.197C361.421 243.934 346.859 236.559 311.254 222.301Z" fill="currentColor" />
            <path d="M273.719 231.195C273.049 227.231 271.888 222.168 271.138 219.944L269.773 215.901L272.307 218.73C275.814 222.645 278.585 227.654 280.933 234.325C282.726 239.417 282.927 240.932 282.914 249.206C282.9 257.33 282.676 259.032 281.021 263.616C278.411 270.843 275.172 275.967 269.737 281.468C259.971 291.354 247.415 296.828 229.295 299.098C226.145 299.492 216.965 300.157 208.895 300.574C188.556 301.626 175.17 303.8 163.142 308C161.412 308.605 159.868 308.972 159.711 308.816C159.225 308.334 167.414 303.479 174.178 300.238C183.716 295.668 193.211 293.175 214.484 289.651C224.992 287.91 235.844 285.799 238.6 284.958C264.624 277.023 278.001 256.544 273.719 231.195Z" fill="currentColor" />
            <path d="M298.228 274.486C291.125 259.299 289.493 244.635 293.385 230.959C293.802 229.498 294.471 228.302 294.874 228.302C295.276 228.302 296.953 229.203 298.598 230.305C301.872 232.496 308.437 236.188 325.928 245.672C347.754 257.507 360.198 266.672 368.66 277.142C376.071 286.312 380.658 296.755 382.865 309.49C384.115 316.704 383.383 334.061 381.521 341.325C375.653 364.229 362.015 382.22 342.562 392.718C339.712 394.256 337.153 395.519 336.876 395.524C336.599 395.53 337.638 392.904 339.184 389.688C345.728 376.083 346.473 362.849 341.526 348.118C338.496 339.099 332.32 328.094 319.849 309.493C305.35 287.868 301.795 282.113 298.228 274.486Z" fill="currentColor" />
            <path d="M97.4084 356.432C117.249 339.771 141.935 327.934 164.422 324.301C174.113 322.735 190.257 323.357 199.231 325.641C213.616 329.302 226.484 337.503 233.176 347.273C239.716 356.823 242.522 365.144 245.443 383.659C246.596 390.962 247.849 398.296 248.228 399.957C250.422 409.552 254.691 417.222 259.982 421.074C268.385 427.191 282.854 427.571 297.087 422.049C299.503 421.111 301.6 420.464 301.748 420.61C302.264 421.12 295.097 425.892 290.04 428.404C283.237 431.784 277.827 433.09 270.638 433.09C257.602 433.09 246.779 426.495 237.748 413.046C235.971 410.399 231.976 402.471 228.871 395.429C219.336 373.799 214.628 367.21 203.556 359.998C193.922 353.723 181.496 352.599 172.148 357.158C159.87 363.146 156.444 378.752 165.238 388.642C168.733 392.573 175.251 395.964 180.581 396.623C190.552 397.856 199.121 390.312 199.121 380.299C199.121 373.798 196.609 370.088 190.284 367.249C181.646 363.372 172.36 367.904 172.405 375.975C172.424 379.417 173.931 381.578 177.4 383.139C179.625 384.14 179.677 384.22 177.862 383.845C169.936 382.21 168.079 372.707 174.452 366.398C182.103 358.824 197.924 362.166 203.357 372.504C205.64 376.847 205.905 385.495 203.915 390.717C199.461 402.405 186.475 408.552 173.302 405.207C164.333 402.93 160.681 400.464 149.867 389.386C131.077 370.135 123.782 366.405 96.6926 362.198L91.5016 361.393L97.4084 356.432Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.2415 11.8782C71.9927 87.6272 115.213 118.88 120.016 125.483C123.981 130.935 122.489 135.836 115.695 139.678C111.917 141.813 104.15 143.977 100.261 143.977C95.8623 143.977 94.3522 142.29 94.3522 142.29C91.8018 139.884 90.3654 140.305 77.2685 117.183C59.0858 89.1221 43.8693 65.8442 43.4542 65.4545C42.4943 64.5531 42.5108 64.5836 75.4146 123.138C80.7309 135.342 76.472 139.822 76.472 141.56C76.472 145.096 75.5021 146.955 71.1164 151.82C63.8053 159.933 60.537 169.047 58.1777 187.911C55.5328 209.058 48.0957 223.995 27.485 249.56C15.4202 264.526 13.446 267.269 10.4018 273.3C6.56722 280.896 5.51286 285.15 5.08553 294.742C4.63386 304.883 5.51354 311.434 8.62869 321.13C11.3559 329.619 14.2026 335.224 21.4801 346.435C27.7605 356.109 31.3768 363.299 31.3768 366.111C31.3768 368.349 31.8063 368.352 41.5383 366.166C64.8282 360.936 83.7395 351.736 94.3756 340.462C100.958 333.483 102.503 329.63 102.554 320.067C102.587 313.812 102.365 312.503 100.665 308.904C97.898 303.048 92.8603 298.178 81.7568 290.629C67.2082 280.737 60.9944 272.774 59.2779 261.822C57.8699 252.836 59.5033 246.496 67.5518 229.719C75.8822 212.353 77.9466 204.953 79.3431 187.449C80.245 176.141 81.4941 171.681 84.761 168.101C88.1682 164.368 91.2354 163.104 99.6675 161.958C113.414 160.09 122.168 156.552 129.363 149.958C135.605 144.238 138.217 138.725 138.618 130.427L138.922 124.138L135.434 120.093C122.802 105.445 0.782165 0.363281 0.00480791 0.363281C-0.161257 0.363281 3.99542 5.54535 9.2415 11.8782ZM38.4675 306.217C41.3235 301.186 39.8061 294.719 35.0288 291.56C30.515 288.574 23.503 289.98 23.503 293.87C23.503 295.057 24.163 295.921 25.6504 296.683C28.155 297.965 28.3368 299.406 26.3662 302.352C24.3705 305.335 24.5316 307.958 26.8207 309.741C30.5099 312.614 35.7324 311.034 38.4675 306.217Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M147.597 165.257C141.144 167.228 134.87 174.031 132.928 181.164C131.744 185.515 132.416 193.149 134.19 195.506C137.057 199.314 139.829 200.317 147.336 200.264C162.033 200.162 174.809 193.893 176.294 186.056C177.512 179.632 171.901 170.729 164.172 166.82C160.183 164.804 151.701 164.004 147.597 165.257ZM164.778 178.619C167.044 175.416 166.053 171.954 162.198 169.613C154.858 165.155 143.757 168.844 143.757 175.741C143.757 179.174 149.546 182.92 154.852 182.92C158.384 182.92 163.217 180.825 164.778 178.619Z" fill="currentColor" />
        </svg>
    </div>
);


interface FloozIconProps {
    className?: string;
    style?: CSSProperties;
}

export const FloozIcon: React.FC<FloozIconProps> = ({ className = "", style }) => (
    <div className={`flooz-icon ${className}`} style={style}>
        <svg
            width="96"
            height="96"
            viewBox="0 0 550 550"
            fill="none"
            version="1.1"
            id="svg8"
            xmlns="http://www.w3.org/2000/svg">
            <defs id="defs8" />
            <path
                d="M 0,115 C 0,51.487 51.487,0 115,0 h 320 c 63.513,0 115,51.487 115,115 v 320 c 0,63.513 -51.487,115 -115,115 H 115 C 51.487,550 0,498.513 0,435 Z"
                fill="currentColor"
                className="flooz-background"
                id="path1" />
            <g
                id="g8"
                transform="translate(-106.7205,2.9985)">
                <path
                    d="m 258.9675,325.2075 11.75,36.361 c 0.402,1.246 2.166,1.246 2.569,0 l 11.749,-36.361 c 6.147,-19.071 21.101,-34.026 40.172,-40.172 l 36.361,-11.749 c 1.247,-0.403 1.247,-2.167 0,-2.569 l -36.361,-11.75 c -19.071,-6.146 -34.025,-21.101 -40.172,-40.172 l -11.749,-36.361 c -0.403,-1.246 -2.167,-1.246 -2.569,0 l -11.75,36.361 c -6.146,19.071 -21.101,34.026 -40.172,40.172 l -36.361,11.75 c -1.246,0.402 -1.246,2.166 0,2.569 l 36.361,11.749 c 19.071,6.146 33.998,21.101 40.172,40.172 z"
                    fill="currentColor"
                    className="flooz-star"
                    id="path2" />
                <path
                    d="m 478.4055,325.2075 11.749,36.361 c 0.403,1.246 2.166,1.246 2.569,0 l 11.75,-36.361 c 6.146,-19.071 21.101,-34.026 40.171,-40.172 l 36.362,-11.749 c 1.246,-0.403 1.246,-2.167 0,-2.569 l -36.362,-11.75 c -19.07,-6.146 -34.025,-21.101 -40.171,-40.172 l -11.75,-36.361 c -0.403,-1.246 -2.166,-1.246 -2.569,0 l -11.749,36.361 c -6.147,19.071 -21.101,34.026 -40.172,40.172 l -36.361,11.75 c -1.247,0.402 -1.247,2.166 0,2.569 l 36.361,11.749 c 19.071,6.146 33.998,21.101 40.172,40.172 z"
                    fill="currentColor"
                    className="flooz-star"
                    id="path3" />
            </g>
        </svg>
    </div>
);